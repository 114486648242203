import Cloud from "./components/Cloud"
import './App.css';

function App() {
  return (
    <>
    <Cloud/>
    </>
  );
}

export default App;
